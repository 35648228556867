import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AdventHealthLandingPage = () => (
  <PhysicianLandingPage
    physician="Advent Health"
    institution="Advent Health"
    referralCode="ADVENT"
    physicianURL="https://www.adventhealthmedicalgroup.com/central-florida/service-line/urology"
  />
)

export default AdventHealthLandingPage
